define("discourse/plugins/discourse-post-voting/discourse/widgets/post-voting-user-commented-notification-item", ["discourse/widgets/default-notification-item", "discourse/widgets/widget", "discourse-common/lib/icon-library", "discourse/lib/utilities", "discourse/plugins/discourse-post-voting/discourse/widgets/post-voting-comment"], function (_defaultNotificationItem, _widget, _iconLibrary, _utilities, _postVotingComment) {
  "use strict";

  (0, _widget.createWidgetFrom)(_defaultNotificationItem.DefaultNotificationItem, "question-answer-user-commented-notification-item", {
    url(data) {
      const attrs = this.attrs;
      const url = (0, _utilities.postUrl)(attrs.slug, attrs.topic_id, attrs.post_number);
      return `${url}#${(0, _postVotingComment.buildAnchorId)(data.post_voting_comment_id)}`;
    },
    icon() {
      return (0, _iconLibrary.iconNode)("comment");
    }
  });
});