define("discourse/plugins/discourse-post-voting/discourse/widgets/post-voting-button", ["exports", "discourse/widgets/widget", "discourse-common/lib/icon-library"], function (_exports, _widget, _iconLibrary) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _widget.createWidget)("post-voting-button", {
    tagName: "button.btn.btn-flat.no-text.post-voting-button",
    buildAttributes(attrs) {
      const attributes = {};
      if (attrs.loading) {
        attributes.disabled = "true";
      }
      return attributes;
    },
    buildClasses(attrs) {
      const result = [];
      if (attrs.direction === "up") {
        result.push("post-voting-button-upvote");
      }
      if (attrs.direction === "down") {
        result.push("post-voting-button-downvote");
      }
      if (attrs.voted) {
        result.push("post-voting-button-voted");
      }
      return result;
    },
    html(attrs) {
      return (0, _iconLibrary.iconNode)(`caret-${attrs.direction}`);
    },
    click() {
      if (this.attrs.loading) {
        return false;
      }
      this.sendWidgetAction(this.attrs.voted ? "removeVote" : "vote", this.attrs.direction);
    }
  });
});