define("discourse/plugins/discourse-post-voting/discourse/widgets/post-voting-comment-editor", ["discourse/widgets/widget", "I18n", "discourse/lib/ajax-error", "discourse/lib/ajax"], function (_widget, _I18n, _ajaxError, _ajax) {
  "use strict";

  (0, _widget.createWidget)("post-voting-comment-editor", {
    tagName: "div",
    buildKey: attrs => `post-voting-comment-editor-${attrs.id}`,
    buildClasses(attrs) {
      return ["post-voting-comment-editor", `post-voting-comment-editor-${attrs.id}`];
    },
    defaultState(attrs) {
      return {
        value: attrs.raw,
        submitDisabled: true
      };
    },
    html(attrs, state) {
      return [this.attach("post-voting-comment-composer", attrs), this.attach("button", {
        action: "editComment",
        disabled: state.submitDisabled,
        contents: _I18n.default.t("post_voting.post.post_voting_comment.edit"),
        icon: "pencil-alt",
        className: "btn-primary post-voting-comment-editor-submit"
      }), this.attach("link", {
        action: "collapseEditor",
        className: "post-voting-comment-editor-cancel",
        contents: () => _I18n.default.t("post_voting.post.post_voting_comment.cancel")
      })];
    },
    updateValue(value) {
      this.state.value = value;
      this.state.submitDisabled = value.length < this.siteSettings.min_post_length || value.length > this.siteSettings.qa_comment_max_raw_length;
    },
    keyDown(e) {
      if (e.key === "Enter" && (e.ctrlKey || e.metaKey)) {
        this.sendWidgetAction("editComment");
      }
    },
    editComment() {
      this.state.submitDisabled = true;
      return (0, _ajax.ajax)("/post_voting/comments", {
        type: "PUT",
        data: {
          comment_id: this.attrs.id,
          raw: this.state.value
        }
      }).then(response => {
        this.sendWidgetAction("updateComment", response);
        this.sendWidgetAction("collapseEditor");
      }).catch(_ajaxError.popupAjaxError).finally(() => {
        this.state.submitDisabled = false;
      });
    }
  });
});