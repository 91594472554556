define("discourse/plugins/discourse-post-voting/discourse/widgets/post-voting-comment", ["exports", "discourse/widgets/widget", "virtual-dom", "discourse/widgets/raw-html", "discourse/helpers/node", "discourse/lib/utilities", "discourse/lib/ajax", "discourse/lib/ajax-error"], function (_exports, _widget, _virtualDom, _rawHtml, _node, _utilities, _ajax, _ajaxError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.buildAnchorId = buildAnchorId;
  _exports.default = void 0;
  function buildAnchorId(commentId) {
    return `post-voting-comment-${commentId}`;
  }
  var _default = _exports.default = (0, _widget.createWidget)("post-voting-comment", {
    tagName: "div",
    buildKey: attrs => `post-voting-comment-${attrs.id}`,
    buildId(attrs) {
      return buildAnchorId(attrs.id);
    },
    buildClasses(attrs) {
      const result = ["post-voting-comment"];
      if (attrs.deleted) {
        result.push("post-voting-comment-deleted");
      }
      return result;
    },
    sendShowLogin() {
      const appRoute = this.register.lookup("route:application");
      appRoute.send("showLogin");
    },
    defaultState() {
      return {
        isEditing: false,
        isVoting: false
      };
    },
    html(attrs, state) {
      if (state.isEditing) {
        return [this.attach("post-voting-comment-editor", attrs)];
      } else {
        const result = [(0, _virtualDom.h)("span.post-voting-comment-cooked", new _rawHtml.default({
          html: attrs.cooked
        })), (0, _virtualDom.h)("span.post-voting-comment-info-separator", "–"), (0, _virtualDom.h)("a.post-voting-comment-info-username", {
          attributes: {
            "data-user-card": attrs.username
          }
        }, (0, _utilities.formatUsername)(attrs.username)), (0, _virtualDom.h)("span.post-voting-comment-info-created", (0, _node.dateNode)(new Date(attrs.created_at)))];
        if (this.currentUser && (attrs.user_id === this.currentUser.id || this.currentUser.admin)) {
          result.push(this.attach("post-voting-comment-actions", attrs));
        }
        let vote_counter = null;
        if (attrs.post_voting_vote_count) {
          vote_counter = (0, _virtualDom.h)("span.post-voting-comment-actions-vote-count", `${attrs.post_voting_vote_count}`);
        }
        return [(0, _virtualDom.h)("div.post-voting-comment-actions-vote", [vote_counter, this.attach("post-voting-button", {
          direction: "up",
          loading: state.isVoting,
          voted: attrs.user_voted
        })]), (0, _virtualDom.h)("div.post-voting-comment-post", result)];
      }
    },
    removeVote() {
      this.state.isVoting = true;
      this.attrs.post_voting_vote_count--;
      this.attrs.user_voted = false;
      return (0, _ajax.ajax)("/post_voting/vote/comment", {
        type: "DELETE",
        data: {
          comment_id: this.attrs.id
        }
      }).catch(e => {
        this.attrs.post_voting_vote_count++;
        this.attrs.user_voted = true;
        (0, _ajaxError.popupAjaxError)(e);
      }).finally(() => {
        this.state.isVoting = false;
      });
    },
    vote(direction) {
      if (!this.currentUser) {
        return this.sendShowLogin();
      }
      if (direction !== "up") {
        return;
      }
      this.state.isVoting = true;
      this.attrs.post_voting_vote_count++;
      this.attrs.user_voted = true;
      return (0, _ajax.ajax)("/post_voting/vote/comment", {
        type: "POST",
        data: {
          comment_id: this.attrs.id
        }
      }).catch(e => {
        this.attrs.post_voting_vote_count--;
        this.attrs.user_voted = false;
        (0, _ajaxError.popupAjaxError)(e);
      }).finally(() => {
        this.state.isVoting = false;
      });
    },
    expandEditor() {
      this.state.isEditing = true;
    },
    collapseEditor() {
      this.state.isEditing = false;
    }
  });
});