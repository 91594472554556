define("discourse/plugins/discourse-post-voting/discourse/widgets/post-voting-comments-menu-composer", ["discourse/widgets/widget", "I18n", "discourse/lib/ajax-error", "discourse/lib/ajax"], function (_widget, _I18n, _ajaxError, _ajax) {
  "use strict";

  (0, _widget.createWidget)("post-voting-comments-menu-composer", {
    tagName: "div.post-voting-comments-menu-composer",
    buildKey: attrs => `post-voting-comments-menu-composer-${attrs.id}`,
    defaultState() {
      return {
        value: "",
        submitDisabled: true
      };
    },
    html(attrs, state) {
      const result = [];
      result.push(this.attach("post-voting-comment-composer", attrs));
      result.push(this.attach("button", {
        action: "submitComment",
        disabled: state.submitDisabled,
        contents: _I18n.default.t("post_voting.post.post_voting_comment.submit"),
        icon: "reply",
        className: "btn-primary post-voting-comments-menu-composer-submit"
      }));
      result.push(this.attach("link", {
        action: "closeComposer",
        className: "post-voting-comments-menu-composer-cancel",
        contents: () => _I18n.default.t("post_voting.post.post_voting_comment.cancel")
      }));
      return result;
    },
    keyDown(e) {
      if (e.key === "Enter" && (e.ctrlKey || e.metaKey)) {
        this.submitComment();
      }
    },
    updateValue(value) {
      this.state.value = value;
      this.state.submitDisabled = value.length < this.siteSettings.min_post_length || value.length > this.siteSettings.qa_comment_max_raw_length;
    },
    submitComment() {
      this.state.submitDisabled = true;
      return (0, _ajax.ajax)("/post_voting/comments", {
        type: "POST",
        data: {
          raw: this.state.value,
          post_id: this.attrs.id
        }
      }).then(response => {
        this.sendWidgetAction("appendComments", [response]);
        this.state.value = "";
        this.sendWidgetAction("closeComposer");
      }).catch(_ajaxError.popupAjaxError).finally(() => {
        this.state.submitDisabled = false;
      });
    }
  });
});