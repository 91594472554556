define("discourse/plugins/discourse-post-voting/discourse/widgets/post-voting-comments-menu", ["exports", "rsvp", "discourse/widgets/widget", "virtual-dom", "discourse/lib/ajax-error", "discourse/lib/ajax", "@ember/runloop", "I18n"], function (_exports, _rsvp, _widget, _virtualDom, _ajaxError, _ajax, _runloop, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _widget.createWidget)("post-voting-comments-menu", {
    tagName: "div.post-voting-comments-menu",
    buildKey: attrs => `post-voting-comments-menu-${attrs.id}`,
    defaultState() {
      return {
        expanded: false
      };
    },
    html(attrs, state) {
      const result = [];
      if (state.expanded) {
        result.push(this.attach("post-voting-comments-menu-composer", attrs));
      } else {
        result.push(this.attach("link", {
          className: "post-voting-comment-add-link",
          action: this.currentUser ? "expandComposer" : "showLogin",
          actionParam: {
            postId: attrs.id,
            postNumber: attrs.postNumber,
            lastCommentId: attrs.lastCommentId
          },
          contents: () => _I18n.default.t("post_voting.post.post_voting_comment.add")
        }));
      }
      if (attrs.moreCommentCount > 0) {
        if (!state.expanded) {
          result.push((0, _virtualDom.h)("span.post-voting-comments-menu-separator"));
        }
        result.push((0, _virtualDom.h)("div.post-voting-comments-menu-show-more", [this.attach("link", {
          className: "post-voting-comments-menu-show-more-link",
          action: "fetchComments",
          actionParam: {
            post_id: attrs.id,
            last_comment_id: attrs.lastCommentId
          },
          contents: () => _I18n.default.t("post_voting.post.post_voting_comment.show", {
            count: attrs.moreCommentCount
          })
        })]));
      }
      return result;
    },
    expandComposer(data) {
      this.state.expanded = true;
      this.fetchComments({
        post_id: data.postId,
        last_comment_id: data.lastCommentId
      }).then(() => {
        (0, _runloop.schedule)("afterRender", () => {
          const textArea = document.querySelector(`#post_${data.postNumber} .post-voting-comment-composer .post-voting-comment-composer-textarea`);
          textArea.focus();
          textArea.select();
        });
      });
    },
    closeComposer() {
      this.state.expanded = false;
    },
    fetchComments(data) {
      if (!data.post_id) {
        return _rsvp.Promise.resolve();
      }
      return (0, _ajax.ajax)("/post_voting/comments", {
        type: "GET",
        data
      }).then(response => {
        if (response.comments.length > 0) {
          this.sendWidgetAction("appendComments", response.comments);
        }
      }).catch(_ajaxError.popupAjaxError);
    }
  });
});