define("discourse/plugins/discourse-post-voting/discourse/widgets/post-voting-comment-actions", ["discourse/widgets/widget", "I18n", "discourse/lib/ajax-error", "discourse/lib/ajax"], function (_widget, _I18n, _ajaxError, _ajax) {
  "use strict";

  (0, _widget.createWidget)("post-voting-comment-actions", {
    tagName: "span.post-voting-comment-actions",
    services: ["dialog"],
    html(attrs) {
      return [this.attach("link", {
        className: "post-voting-comment-actions-edit-link",
        action: "expandEditor",
        icon: "pencil-alt"
      }), this.attach("link", {
        className: "post-voting-comment-actions-delete-link",
        action: "deleteComment",
        icon: "far-trash-alt",
        actionParam: {
          comment_id: attrs.id
        }
      })];
    },
    deleteComment(data) {
      this.dialog.deleteConfirm({
        message: _I18n.default.t("post_voting.post.post_voting_comment.delete.confirm"),
        didConfirm: () => {
          (0, _ajax.ajax)("/post_voting/comments", {
            type: "DELETE",
            data
          }).then(() => {
            this.sendWidgetAction("removeComment", data.comment_id);
          }).catch(_ajaxError.popupAjaxError);
        }
      });
    }
  });
});